import { useAcceptOrRejectLease } from 'features/officer/lease-management/hooks/useAcceptOrRejectLease'
import type React from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useOfficerStore } from 'stores/useOfficerStore'
import { type Lease } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import type z from 'zod'
import { array, boolean, object } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Checkbox } from 'components/ui/checkbox'
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel
} from 'components/ui'

interface ProvidedInformationProps {
  lease: Lease
  className?: string
  onApprove: () => void
  onCancel: () => void
}

const FormSchema = object({
  declarations: array(boolean().default(false)).refine((value) => value.every((item) => item), {
    message: 'You have to select at least one item.'
  })
})

const items = [
  {
    id: 'omang',
    label: 'Certified copy of OMANGs'
  },
  {
    id: 'affidavit',
    label: 'Affidavit'
  },
  {
    id: 'correctInformation',
    label: 'The information provided is correct'
  }
]

export const ProvidedInformation: React.FC<ProvidedInformationProps> = ({
  className,
  lease,
  onApprove,
  onCancel
}) => {
  const { officer } = useOfficerStore()
  const { acceptLease, response, isLoading, error } = useAcceptOrRejectLease(
    lease,
    officer?.user_id
  )

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      declarations: [false, false, false]
    }
  })

  const onSubmit = (data: z.infer<typeof FormSchema>): void => {
    acceptLease()
  }

  useEffect(() => {
    if (error !== null) {
      toast.error(mapCmsErrorToMessage(error))
    }

    if (response?.status === true) {
      // reference(response.reference)
      onApprove()
    }
  }, [error, response])

  return (
    <Form {...form}>
      <form className={className} onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name='declarations'
          render={() => (
            <FormItem className='flex flex-col items-start space-x-3 space-y-0 p-4'>
              <div className='flex flex-col gap-2'>
                <FormLabel className={'text-center'}>DECLARATION BY EXTENSION OFFICER</FormLabel>
                <FormDescription className={'flex flex-col gap-2 text-center'}>
                  I certify that the information given is true and complete to the best of my
                  knowledge. I understand that if I have deliberately given any false information or
                  have withheld any information regarding any Temo Letlotlo Programme transactions,
                  I am liable for prosecution for fraud and/or perjury.
                  <span className={'text-center text-muted-foreground'}>
                    I{' '}
                    <span className={'text-lg text-color-text font-bold'}>
                      {officer?.firstname} {officer?.surname}
                    </span>
                    , acknowledge receipt of the following:
                  </span>
                </FormDescription>
              </div>
              {items.map((item, index) => (
                <FormField
                  key={item.id}
                  control={form.control}
                  name='declarations'
                  render={({ field }) => (
                    <FormItem
                      key={item.id}
                      className='flex flex-row items-start space-x-3 space-y-0 p-2'
                    >
                      <FormControl>
                        <Checkbox
                          checked={field.value[index]}
                          onCheckedChange={(checked) => {
                            const arr = field.value
                            if (checked === true) {
                              arr[index] = true
                              field.onChange(arr)
                            } else {
                              arr[index] = false
                              field.onChange(arr)
                            }
                          }}
                        />
                      </FormControl>
                      <div className='space-y-1 leading-none'>
                        <FormLabel>{item.label}</FormLabel>
                      </div>
                    </FormItem>
                  )}
                />
              ))}
            </FormItem>
          )}
        />

        <div className={'flex flex-row justify-between gap-8'}>
          <Button variant={'destructive'} onClick={onCancel} size={'xs'} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            variant={'success'}
            type={'submit'}
            onClick={form.handleSubmit(onSubmit)}
            size={'xs'}
            disabled={isLoading}
            loading={isLoading}
          >
            Continue
          </Button>
        </div>
      </form>
    </Form>
  )
}
