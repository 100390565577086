import { useAcceptOrRejectFarm } from 'features/officer/land-management/hooks/useAcceptOrRejectFarm'
import type React from 'react'
import { useEffect } from 'react'
import { useOfficerStore } from 'stores/useOfficerStore'
import { type Farm } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { OTPInput } from '../../../../../components/Otp/InputOTP'
import { useToast } from '../../../../../components/ui/use-toast'

interface RejectFarmOtpFormProps {
  farm: Farm
  onSuccess: () => void
  reason: string
  reference: React.Dispatch<React.SetStateAction<string>>
}

export const RejectFarmOtpForm: React.FC<RejectFarmOtpFormProps> = ({
  farm,
  onSuccess,
  reason,
  reference
}) => {
  const { officer } = useOfficerStore()
  const { toast } = useToast()

  const {
    rejectFarm,
    response,
    isLoading: apiIsLoading,
    error: apiError
  } = useAcceptOrRejectFarm(farm, officer?.user_id, reason)

  useEffect(() => {
    if (response?.status === true) {
      reference(response.reference)
      onSuccess()
    }

    if (apiError !== null) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: mapCmsErrorToMessage(apiError)
      })
    }
  }, [apiError, response])

  return <OTPInput isLoading={apiIsLoading} number={farm.farmer_contact} next={rejectFarm} />
}
