/* eslint-disable prettier/prettier */
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { InputAdornment, TextField } from '@mui/material'
import type React from 'react'
import { type MouseEventHandler } from 'react'
import { type UseFormRegisterReturn } from 'react-hook-form'
import { Text } from 'components/Elements/Text/Text'
interface InputFieldProps {
  type?: 'text' | 'email' | 'number' | 'password' | 'textarea'
  className?: string
  label: string
  register?: Partial<UseFormRegisterReturn>
  error: boolean
  isPassword?: boolean
  showPassword?: boolean
  onShowPassword?: MouseEventHandler<SVGSVGElement> | undefined
  helperText: string
  inputMode?:
    | 'text'
    | 'email'
    | 'search'
    | 'tel'
    | 'url'
    | 'none'
    | 'numeric'
    | 'decimal'
    | undefined
  icon?: React.ReactNode
  endIcon?: React.ReactNode
  defaultValue?: string | number
  disabled?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const InputField: React.FC<InputFieldProps> = ({
  type = 'text',
  register,
  error,
  helperText,
  showPassword = false,
  onShowPassword,
  isPassword = false,
  label,
  inputMode,
  className,
  icon,
  defaultValue,
  endIcon,
  disabled,
  onChange
}: InputFieldProps) => {
  return (
    <TextField
      className={className}
      variant='outlined'
      id={type === 'number' ? 'outlined-basic' : undefined}
      defaultValue={defaultValue}
      size={type === 'textarea' ? 'medium' : 'small'}
      fullWidth
      sx={{
        '& label': {
          fontFamily: 'Red Hat Display'
        }
      }}
      label={label}
      InputLabelProps={{
        shrink: true
      }}
      onWheel={(e) => {
        e.currentTarget.blur()
      }}
      InputProps={{
        inputMode,
        type,
        inputProps: {
          pattern: type === 'text' && inputMode === 'numeric' ? '[0-9]*' : undefined
        },
        startAdornment: <InputAdornment position='start'>{icon}</InputAdornment>,
        endAdornment: isPassword ? (
          <InputAdornment position='end'>
            {showPassword ? (
              <VisibilityOff
                className={'cursor-pointer'}
                color={'primary'}
                onClick={onShowPassword}
              />
            ) : endIcon !== undefined ? (
              endIcon
            ) : (
              <Visibility className={'cursor-pointer'} color={'primary'} onClick={onShowPassword} />
            )}
          </InputAdornment>
        ) : null
      }}
      error={error}
      helperText={<Text variant={'error'}>{helperText}</Text>}
      disabled={disabled}
      onChange={onChange}
      {...register}
    />
  )
}
