import { type Seeds } from 'features/farmer/cropping-plan/types'

export const SeedsList: Seeds[] = [
  {
    seed_id: 1,
    trade_name: 'ANNAR',
    crop_id: 10016,
    variety: 'AN 3M-05',
    seed_type: 'HYBRID',
    price_per_kg: 35.75,
    sowing_rate: '10-25',
    days_to_maturity: '95-120',
    yield: '4-8'
  },
  {
    seed_id: 2,
    trade_name: 'ANNAR',
    crop_id: 10016,
    variety: 'AN 413 ',
    seed_type: 'HYBRID',
    price_per_kg: 40.98,
    sowing_rate: '10-25',
    days_to_maturity: '125-135',
    yield: '4-8'
  },
  {
    seed_id: 3,
    trade_name: 'ANNAR',
    crop_id: 10016,
    variety: 'AN 4M-19',
    seed_type: 'HYBRID',
    price_per_kg: 38.62,
    sowing_rate: '10-25',
    days_to_maturity: '128-135',
    yield: '8-10'
  },
  {
    seed_id: 4,
    trade_name: 'ANNAR',
    crop_id: 10016,
    variety: 'AN 53',
    seed_type: 'HYBRID',
    price_per_kg: 41.18,
    sowing_rate: '10-25',
    days_to_maturity: '133-140',
    yield: '8-12'
  },
  {
    seed_id: 5,
    trade_name: 'ANNAR',
    crop_id: 10016,
    variety: 'AN 7M-81',
    seed_type: 'HYBRID',
    price_per_kg: 71.68,
    sowing_rate: '10-25',
    days_to_maturity: '138-145',
    yield: '8-12'
  },
  {
    seed_id: 6,
    trade_name: 'ANNAR',
    crop_id: 10016,
    variety: 'AN 7M-83',
    seed_type: 'HYBRID',
    price_per_kg: 71.68,
    sowing_rate: '10-25',
    days_to_maturity: '138-145',
    yield: '8-12'
  },
  {
    seed_id: 7,
    trade_name: 'ANNAR',
    crop_id: 10017,
    variety: 'AN 4A-156',
    seed_type: 'HYBRID',
    price_per_kg: 71.57,
    sowing_rate: '10-25',
    days_to_maturity: '110-150',
    yield: '8-12'
  },
  {
    seed_id: 8,
    trade_name: 'ANNAR',
    crop_id: 10017,
    variety: 'AN 5A-190',
    seed_type: 'HYBRID',
    price_per_kg: 83.79,
    sowing_rate: '10-25',
    days_to_maturity: '115-155',
    yield: '8-12'
  },
  {
    seed_id: 9,
    trade_name: 'ANNAR',
    crop_id: 10017,
    variety: 'AN 6M-50',
    seed_type: 'HYBRID',
    price_per_kg: 83.79,
    sowing_rate: '10-25',
    days_to_maturity: '115-162',
    yield: '8-12'
  },
  {
    seed_id: 10,
    trade_name: 'ANNAR',
    crop_id: 10008,
    variety: 'AN 148',
    seed_type: 'HYBRID',
    price_per_kg: 83.79,
    sowing_rate: '10-25',
    days_to_maturity: '100-120',
    yield: '1.5-3'
  },
  {
    seed_id: 11,
    trade_name: 'ANNAR',
    crop_id: 10008,
    variety: 'AN 9216',
    seed_type: 'HYBRID',
    price_per_kg: 83.79,
    sowing_rate: '10-25',
    days_to_maturity: '100-120',
    yield: '1.5-3'
  },
  {
    seed_id: 12,
    trade_name: 'ANNAR',
    crop_id: 10003,
    variety: 'AN 9292',
    seed_type: 'HYBRID',
    price_per_kg: 83.79,
    sowing_rate: '10-25',
    days_to_maturity: '100-120',
    yield: '1.5-3'
  },
  {
    seed_id: 14,
    trade_name: 'ANNAR',
    crop_id: 10009,
    variety: 'AN 868',
    seed_type: 'HYBRID',
    price_per_kg: 78.43,
    sowing_rate: '10-25',
    days_to_maturity: '130',
    yield: '4-6'
  },
  {
    seed_id: 15,
    trade_name: 'ANNAR',
    crop_id: 10009,
    variety: 'AN 888',
    seed_type: 'HYBRID',
    price_per_kg: 78.43,
    sowing_rate: '10-25',
    days_to_maturity: '81',
    yield: '4-6'
  },
  {
    seed_id: 16,
    trade_name: 'ANNAR',
    crop_id: 10009,
    variety: 'SILAGE KING',
    seed_type: 'HYBRID',
    price_per_kg: 78.43,
    sowing_rate: '10-25',
    days_to_maturity: '81',
    yield: '4-6'
  },
  {
    seed_id: 17,
    trade_name: 'CAPSTONE',
    crop_id: 10017,
    variety: 'CAP 122-60',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10-25',
    days_to_maturity: '120-130',
    yield: '8'
  },
  {
    seed_id: 18,
    trade_name: 'CAPSTONE',
    crop_id: 10017,
    variety: 'CAP 9-522',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10-25',
    days_to_maturity: '130-140',
    yield: '9'
  },
  {
    seed_id: 19,
    trade_name: 'CAPSTONE',
    crop_id: 10017,
    variety: 'CAP 9-646',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10-25',
    days_to_maturity: '130-135',
    yield: '12'
  },
  {
    seed_id: 20,
    trade_name: 'CAPSTONE',
    crop_id: 10017,
    variety: 'CAP 9-644',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10-25',
    days_to_maturity: '90-115',
    yield: '8'
  },
  {
    seed_id: 21,
    trade_name: 'CAPSTONE',
    crop_id: 10017,
    variety: 'SAHARA',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '10',
    days_to_maturity: '135-140',
    yield: '5'
  },
  {
    seed_id: 22,
    trade_name: 'CAPSTONE',
    crop_id: 10017,
    variety: 'OKAVANGO DENT',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '10',
    days_to_maturity: '131-152',
    yield: '8'
  },
  {
    seed_id: 23,
    trade_name: 'CAPSTONE',
    crop_id: 10017,
    variety: 'OKAVANGO FLINT',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '10',
    days_to_maturity: '131-152',
    yield: '8'
  },
  {
    seed_id: 24,
    trade_name: 'CAPSTONE',
    crop_id: 10016,
    variety: 'ISILO',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '10',
    days_to_maturity: '100-110',
    yield: '8'
  },
  {
    seed_id: 25,
    trade_name: 'CAPSTONE',
    crop_id: 10016,
    variety: 'SHESHA',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '10',
    days_to_maturity: '90-110',
    yield: '8'
  },
  {
    seed_id: 26,
    trade_name: 'CAPSTONE',
    crop_id: 10016,
    variety: 'NELSON"S CHOICE',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '10',
    days_to_maturity: '121-135',
    yield: '9'
  },
  {
    seed_id: 27,
    trade_name: 'CAPSTONE',
    crop_id: 10016,
    variety: 'NELSON"S CHOICE QPM',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '10',
    days_to_maturity: '121-135',
    yield: '8'
  },
  {
    seed_id: 28,
    trade_name: 'CAPSTONE',
    crop_id: 10016,
    variety: 'ZM 1521',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '10',
    days_to_maturity: '121-135',
    yield: '11'
  },
  {
    seed_id: 29,
    trade_name: 'CAPSTONE',
    crop_id: 10016,
    variety: 'ZM 1421',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '10',
    days_to_maturity: '121-135',
    yield: '8'
  },
  {
    seed_id: 30,
    trade_name: 'CAPSTONE',
    crop_id: 10016,
    variety: 'ZM 1523',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '10',
    days_to_maturity: '121-135',
    yield: '6'
  },
  {
    seed_id: 31,
    trade_name: 'CAPSTONE',
    crop_id: 10016,
    variety: 'CAP 9299',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10-25',
    days_to_maturity: '90-115',
    yield: '8'
  },
  {
    seed_id: 32,
    trade_name: 'CAPSTONE',
    crop_id: 10016,
    variety: 'CAP 9021',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10-25',
    days_to_maturity: '120-135',
    yield: '10'
  },
  {
    seed_id: 33,
    trade_name: 'CAPSTONE',
    crop_id: 10016,
    variety: 'CAP 9705',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10-25',
    days_to_maturity: '122-134',
    yield: '12'
  },
  {
    seed_id: 34,
    trade_name: 'CAPSTONE',
    crop_id: 10016,
    variety: 'CAP 9-619',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10-25',
    days_to_maturity: '120-130',
    yield: '10'
  },
  {
    seed_id: 35,
    trade_name: 'CAPSTONE',
    crop_id: 10016,
    variety: 'CAP 9503',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10-25',
    days_to_maturity: '130-140',
    yield: '10'
  },
  {
    seed_id: 36,
    trade_name: 'CAPSTONE',
    crop_id: 10016,
    variety: 'CAP 9001',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10-25',
    days_to_maturity: '135-145',
    yield: '8'
  },
  {
    seed_id: 37,
    trade_name: 'CAPSTONE',
    crop_id: 10016,
    variety: 'CAP 9-745',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10-25',
    days_to_maturity: '125-130',
    yield: '12'
  },
  {
    seed_id: 38,
    trade_name: 'CAPSTONE',
    crop_id: 10016,
    variety: 'CAP 341 NG',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10-25',
    days_to_maturity: '125-130',
    yield: '8'
  },
  {
    seed_id: 39,
    trade_name: 'CAPSTONE',
    crop_id: 10003,
    variety: 'JUGO',
    seed_type: 'OPV',
    price_per_kg: 39.6,
    sowing_rate: '65-100',
    days_to_maturity: '90-170',
    yield: '2'
  },
  {
    seed_id: 40,
    trade_name: 'CAPSTONE',
    crop_id: 10003,
    variety: 'CAP 2000',
    seed_type: 'HYBRID',
    price_per_kg: 71.5,
    sowing_rate: '65-100',
    days_to_maturity: '100-110',
    yield: '4'
  },
  {
    seed_id: 41,
    trade_name: 'CAPSTONE',
    crop_id: 10003,
    variety: 'CAP 2001',
    seed_type: 'HYBRID',
    price_per_kg: 71.5,
    sowing_rate: '65-100',
    days_to_maturity: '90',
    yield: '4'
  },
  {
    seed_id: 42,
    trade_name: 'CAPSTONE',
    crop_id: 10004,
    variety: 'AKWA',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '75-100',
    days_to_maturity: '150',
    yield: '3'
  },
  {
    seed_id: 43,
    trade_name: 'CAPSTONE',
    crop_id: 10004,
    variety: 'KWARTS',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '75-100',
    days_to_maturity: '150',
    yield: '3'
  },
  {
    seed_id: 44,
    trade_name: 'CAPSTONE',
    crop_id: 10004,
    variety: 'ANEL',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '75-100',
    days_to_maturity: '150',
    yield: '3'
  },
  {
    seed_id: 45,
    trade_name: 'CAPSTONE',
    crop_id: 10004,
    variety: 'SELLIE PLUS',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '75-100',
    days_to_maturity: '150',
    yield: '3'
  },
  {
    seed_id: 46,
    trade_name: 'CAPSTONE',
    crop_id: 10004,
    variety: 'TUFA',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '75-100',
    days_to_maturity: '150',
    yield: '3'
  },
  {
    seed_id: 47,
    trade_name: 'CAPSTONE',
    crop_id: 10014,
    variety: 'CAP 1003',
    seed_type: 'HYBRID',
    price_per_kg: 110.0,
    sowing_rate: '5-10',
    days_to_maturity: '100-105',
    yield: '8'
  },
  {
    seed_id: 48,
    trade_name: 'CAPSTONE',
    crop_id: 10014,
    variety: 'CAP 1004',
    seed_type: 'HYBRID',
    price_per_kg: 110.0,
    sowing_rate: '5-10',
    days_to_maturity: '100-105',
    yield: '8'
  },
  {
    seed_id: 49,
    trade_name: 'CAPSTONE',
    crop_id: 10014,
    variety: 'CAP 1005',
    seed_type: 'HYBRID',
    price_per_kg: 110.0,
    sowing_rate: '5-10',
    days_to_maturity: '140-145',
    yield: '8'
  },
  {
    seed_id: 50,
    trade_name: 'CAPSTONE',
    crop_id: 10014,
    variety: 'ARROW',
    seed_type: 'HYBRID',
    price_per_kg: 110.0,
    sowing_rate: '5-10',
    days_to_maturity: '100-105',
    yield: '8'
  },
  {
    seed_id: 51,
    trade_name: 'CAPSTONE',
    crop_id: 10014,
    variety: 'NS 5511',
    seed_type: 'HYBRID',
    price_per_kg: 110.0,
    sowing_rate: '5-10',
    days_to_maturity: '140-145',
    yield: '8'
  },
  {
    seed_id: 52,
    trade_name: 'CAPSTONE',
    crop_id: 10014,
    variety: 'NS 5655',
    seed_type: 'HYBRID',
    price_per_kg: 110.0,
    sowing_rate: '5-10',
    days_to_maturity: '140-145',
    yield: '8'
  },
  {
    seed_id: 53,
    trade_name: 'CAPSTONE',
    crop_id: 10014,
    variety: 'MACIA',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '5-10',
    days_to_maturity: '115-120',
    yield: '5'
  },
  {
    seed_id: 54,
    trade_name: 'CAPSTONE',
    crop_id: 10019,
    variety: 'CAP 4000',
    seed_type: 'HYBRID',
    price_per_kg: 649.0,
    sowing_rate: '3-5',
    days_to_maturity: '140',
    yield: '2'
  },
  {
    seed_id: 55,
    trade_name: 'CAPSTONE',
    crop_id: 10019,
    variety: 'CAP 4064',
    seed_type: 'HYBRID',
    price_per_kg: 649.0,
    sowing_rate: '3-5',
    days_to_maturity: '140',
    yield: '2'
  },
  {
    seed_id: 56,
    trade_name: 'CAPSTONE',
    crop_id: 10019,
    variety: 'CAP 4065',
    seed_type: 'HYBRID',
    price_per_kg: 649.0,
    sowing_rate: '3-5',
    days_to_maturity: '140',
    yield: '2'
  },
  {
    seed_id: 57,
    trade_name: 'CAPSTONE',
    crop_id: 10007,
    variety: 'DUNDEE ',
    seed_type: 'HYBRID',
    price_per_kg: 33.0,
    sowing_rate: '50-75',
    days_to_maturity: '137-160',
    yield: '3'
  },
  {
    seed_id: 58,
    trade_name: 'CAPSTONE',
    crop_id: 10007,
    variety: 'HERON',
    seed_type: 'HYBRID',
    price_per_kg: 33.0,
    sowing_rate: '50-75',
    days_to_maturity: '137-160',
    yield: '4'
  },
  {
    seed_id: 59,
    trade_name: 'CAPSTONE',
    crop_id: 10007,
    variety: 'EGRET',
    seed_type: 'HYBRID',
    price_per_kg: 33.0,
    sowing_rate: '50-75',
    days_to_maturity: '140-165',
    yield: '4'
  },
  {
    seed_id: 60,
    trade_name: 'CAPSTONE',
    crop_id: 10002,
    variety: 'RONGAI',
    seed_type: 'HYBRID',
    price_per_kg: 66.0,
    sowing_rate: '50',
    days_to_maturity: '90-120',
    yield: '7'
  },
  {
    seed_id: 61,
    trade_name: 'CAPSTONE',
    crop_id: 10002,
    variety: 'HIGH WORTH',
    seed_type: 'HYBRID',
    price_per_kg: 66.0,
    sowing_rate: '15-30',
    days_to_maturity: '90-120',
    yield: '7'
  },
  {
    seed_id: 62,
    trade_name: 'CAPSTONE',
    crop_id: 10001,
    variety: 'BROWN MIXED',
    seed_type: 'OPV',
    price_per_kg: 41.8,
    sowing_rate: '50',
    days_to_maturity: '120',
    yield: '2'
  },
  {
    seed_id: 63,
    trade_name: 'CAPSTONE',
    crop_id: 10001,
    variety: 'GLENDA',
    seed_type: 'OPV',
    price_per_kg: 41.8,
    sowing_rate: '10-15',
    days_to_maturity: '90-118',
    yield: '2'
  },
  {
    seed_id: 64,
    trade_name: 'CAPSTONE',
    crop_id: 10001,
    variety: 'DR SAUNDERS',
    seed_type: 'OPV',
    price_per_kg: 41.8,
    sowing_rate: '15-20',
    days_to_maturity: '120',
    yield: '2'
  },
  {
    seed_id: 65,
    trade_name: 'CAPSTONE',
    crop_id: 10001,
    variety: 'BECHUANA WHITE',
    seed_type: 'OPV',
    price_per_kg: 41.8,
    sowing_rate: '15-30',
    days_to_maturity: '120',
    yield: '2'
  },
  {
    seed_id: 66,
    trade_name: 'CAPSTONE',
    crop_id: 10001,
    variety: 'IT18',
    seed_type: 'OPV',
    price_per_kg: 41.8,
    sowing_rate: '50',
    days_to_maturity: '90-100',
    yield: '2'
  },
  {
    seed_id: 67,
    trade_name: 'CAPSTONE',
    crop_id: 10001,
    variety: 'BLACK EYED COWPEAS',
    seed_type: 'OPV',
    price_per_kg: 41.8,
    sowing_rate: '15-30',
    days_to_maturity: '120',
    yield: '2'
  },
  {
    seed_id: 68,
    trade_name: 'ZAMSEED',
    crop_id: 10011,
    variety: 'ZMS 608Y',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10',
    days_to_maturity: '115-120',
    yield: '10 -12'
  },
  {
    seed_id: 69,
    trade_name: 'ZAMSEED',
    crop_id: 10011,
    variety: 'ZMS 606',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10',
    days_to_maturity: '125-130',
    yield: '8 - 9'
  },
  {
    seed_id: 70,
    trade_name: 'ZAMSEED',
    crop_id: 10011,
    variety: 'ZMS 520',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10',
    days_to_maturity: '120-125',
    yield: '9 - 10'
  },
  {
    seed_id: 71,
    trade_name: 'ZAMSEED',
    crop_id: 10011,
    variety: 'ZMS 528',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10',
    days_to_maturity: '110-120',
    yield: '7 - 8'
  },
  {
    seed_id: 72,
    trade_name: 'ZAMSEED',
    crop_id: 10011,
    variety: 'ZMS 405',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10',
    days_to_maturity: '100-105',
    yield: '7 - 8'
  },
  {
    seed_id: 73,
    trade_name: 'ZAMSEED',
    crop_id: 10011,
    variety: 'ZMS 301',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10',
    days_to_maturity: '75 - 80',
    yield: '6 -7'
  },
  {
    seed_id: 74,
    trade_name: 'ZAMSEED',
    crop_id: 10011,
    variety: 'ZM 523',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '10',
    days_to_maturity: '120 - 130',
    yield: '5 - 6'
  },
  {
    seed_id: 75,
    trade_name: 'ZAMSEED',
    crop_id: 10011,
    variety: 'ZM 521',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '10',
    days_to_maturity: '120 - 130',
    yield: '4 - 6'
  },
  {
    seed_id: 76,
    trade_name: 'ZAMSEED',
    crop_id: 10014,
    variety: 'ZSV 17',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '3',
    days_to_maturity: '112 - 118',
    yield: '4- 5'
  },
  {
    seed_id: 77,
    trade_name: 'ZAMSEED',
    crop_id: 10014,
    variety: 'ZSV 36R',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '3',
    days_to_maturity: '110 - 120',
    yield: '2 -5'
  },
  {
    seed_id: 78,
    trade_name: 'ZAMSEED',
    crop_id: 10014,
    variety: 'ZSH375',
    seed_type: 'HYBRID',
    price_per_kg: 110.0,
    sowing_rate: '3',
    days_to_maturity: '110-120',
    yield: '6-8'
  },
  {
    seed_id: 79,
    trade_name: 'ZAMSEED',
    crop_id: 10014,
    variety: 'MR BUSTER',
    seed_type: 'HYBRID',
    price_per_kg: 160.0,
    sowing_rate: '3-8',
    days_to_maturity: '80-110 ',
    yield: '6'
  },
  {
    seed_id: 80,
    trade_name: 'ZAMSEED',
    crop_id: 10001,
    variety: 'LUTEMBWE',
    seed_type: 'OPV',
    price_per_kg: 41.8,
    sowing_rate: '15 - 20',
    days_to_maturity: '115 - 200',
    yield: '1.5- 2'
  },
  {
    seed_id: 81,
    trade_name: 'ZAMSEED',
    crop_id: 10007,
    variety: 'KALEYA',
    seed_type: 'OPV',
    price_per_kg: 22.0,
    sowing_rate: '100',
    days_to_maturity: '110-125',
    yield: '2.5 - 3'
  },
  {
    seed_id: 82,
    trade_name: 'ZAMSEED',
    crop_id: 10007,
    variety: 'LUKANGA',
    seed_type: 'OPV',
    price_per_kg: 22.0,
    sowing_rate: '100',
    days_to_maturity: '115 -120',
    yield: '2 -4'
  },
  {
    seed_id: 83,
    trade_name: 'ZAMSEED',
    crop_id: 10019,
    variety: 'MILIKA',
    seed_type: 'OPV',
    price_per_kg: 44.0,
    sowing_rate: '5',
    days_to_maturity: '100-125',
    yield: '1.5 - 2'
  },
  {
    seed_id: 84,
    trade_name: 'ZAMSEED',
    crop_id: 10019,
    variety: 'RECORD',
    seed_type: 'OPV',
    price_per_kg: 44.0,
    sowing_rate: '5',
    days_to_maturity: '100 -125',
    yield: '1.5 - 2'
  },
  {
    seed_id: 85,
    trade_name: 'ZAMSEED',
    crop_id: 10004,
    variety: 'MGV 4',
    seed_type: 'OPV',
    price_per_kg: 40.7,
    sowing_rate: '80',
    days_to_maturity: '130-140',
    yield: '2 - 3'
  },
  {
    seed_id: 86,
    trade_name: 'ZAMSEED',
    crop_id: 10004,
    variety: 'MGV 5',
    seed_type: 'OPV',
    price_per_kg: 40.7,
    sowing_rate: '80',
    days_to_maturity: '100 -130',
    yield: '2.5 -3'
  },
  {
    seed_id: 87,
    trade_name: 'ZAMSEED',
    crop_id: 10041,
    variety: 'KAUFELA',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '3-4',
    days_to_maturity: '90-130',
    yield: '2-5'
  },
  {
    seed_id: 88,
    trade_name: 'ZAMSEED',
    crop_id: 10041,
    variety: 'LUBASI',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '3-4',
    days_to_maturity: '90-130',
    yield: '2-5'
  },
  {
    seed_id: 89,
    trade_name: 'ZAMSEED',
    crop_id: 10041,
    variety: 'DOLA',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '2',
    days_to_maturity: '70-75',
    yield: '2'
  },
  {
    seed_id: 90,
    trade_name: 'SEEDCO',
    crop_id: 10011,
    variety: 'SC303',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10',
    days_to_maturity: '110',
    yield: '3'
  },
  {
    seed_id: 91,
    trade_name: 'SEEDCO',
    crop_id: 10011,
    variety: 'SC301',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10',
    days_to_maturity: '110',
    yield: '3'
  },
  {
    seed_id: 92,
    trade_name: 'SEEDCO',
    crop_id: 10011,
    variety: 'SC402',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10',
    days_to_maturity: '120',
    yield: '3.5'
  },
  {
    seed_id: 93,
    trade_name: 'SEEDCO',
    crop_id: 10011,
    variety: 'SC403',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10',
    days_to_maturity: '120',
    yield: '3.5'
  },
  {
    seed_id: 94,
    trade_name: 'SEEDCO',
    crop_id: 10011,
    variety: 'SC419',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10',
    days_to_maturity: '125',
    yield: '3.5'
  },
  {
    seed_id: 95,
    trade_name: 'SEEDCO',
    crop_id: 10011,
    variety: 'SC506',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10',
    days_to_maturity: '125',
    yield: '4'
  },
  {
    seed_id: 96,
    trade_name: 'SEEDCO',
    crop_id: 10011,
    variety: 'SC513',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10',
    days_to_maturity: '125',
    yield: '4'
  },
  {
    seed_id: 97,
    trade_name: 'SEEDCO',
    crop_id: 10011,
    variety: 'SC555',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10',
    days_to_maturity: '125',
    yield: '4'
  },
  {
    seed_id: 98,
    trade_name: 'SEEDCO',
    crop_id: 10011,
    variety: 'SC602',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10',
    days_to_maturity: '130',
    yield: '5'
  },
  {
    seed_id: 99,
    trade_name: 'SEEDCO',
    crop_id: 10011,
    variety: 'SC608',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10',
    days_to_maturity: '130',
    yield: '5'
  },
  {
    seed_id: 100,
    trade_name: 'SEEDCO',
    crop_id: 10011,
    variety: 'SC663',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10',
    days_to_maturity: '130',
    yield: '5'
  },
  {
    seed_id: 101,
    trade_name: 'SEEDCO',
    crop_id: 10011,
    variety: 'SC701',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10',
    days_to_maturity: '140',
    yield: '7'
  },
  {
    seed_id: 102,
    trade_name: 'SEEDCO',
    crop_id: 10011,
    variety: 'SC719',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10',
    days_to_maturity: '140',
    yield: '7'
  },
  {
    seed_id: 103,
    trade_name: 'SEEDCO',
    crop_id: 10011,
    variety: 'SC727',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10',
    days_to_maturity: '140',
    yield: '7'
  },
  {
    seed_id: 104,
    trade_name: 'SEEDCO',
    crop_id: 10011,
    variety: 'COLORADO',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '10',
    days_to_maturity: '120',
    yield: '1'
  },
  {
    seed_id: 105,
    trade_name: 'SEEDCO',
    crop_id: 10011,
    variety: 'ZM521',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '10',
    days_to_maturity: '120',
    yield: '1'
  },
  {
    seed_id: 106,
    trade_name: 'SEEDCO',
    crop_id: 10011,
    variety: 'ZM523',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '10',
    days_to_maturity: '120',
    yield: '1'
  },
  {
    seed_id: 107,
    trade_name: 'SEEDCO',
    crop_id: 10001,
    variety: 'BLACK EYE',
    seed_type: 'OPV',
    price_per_kg: 41.8,
    sowing_rate: '10',
    days_to_maturity: '110',
    yield: '1.5'
  },
  {
    seed_id: 108,
    trade_name: 'SEEDCO',
    crop_id: 10001,
    variety: 'BROWN MIX',
    seed_type: 'OPV',
    price_per_kg: 41.8,
    sowing_rate: '10',
    days_to_maturity: '110',
    yield: '1.5'
  },
  {
    seed_id: 109,
    trade_name: 'SEEDCO',
    crop_id: 10001,
    variety: 'SVU01',
    seed_type: 'OPV',
    price_per_kg: 41.8,
    sowing_rate: '10',
    days_to_maturity: '110',
    yield: '2'
  },
  {
    seed_id: 110,
    trade_name: 'SEEDCO',
    crop_id: 10005,
    variety: 'RED BAMBARA',
    seed_type: 'OPV',
    price_per_kg: 44.0,
    sowing_rate: '10',
    days_to_maturity: '120',
    yield: '1.5'
  },
  {
    seed_id: 111,
    trade_name: 'SEEDCO',
    crop_id: 10006,
    variety: 'SUPERSONIC',
    seed_type: 'HYBRID',
    price_per_kg: 118.8,
    sowing_rate: '50',
    days_to_maturity: '90',
    yield: '8.5'
  },
  {
    seed_id: 113,
    trade_name: 'SEEDCO',
    crop_id: 10007,
    variety: 'SC SPIKE',
    seed_type: 'HYBRID',
    price_per_kg: 20.79,
    sowing_rate: '25',
    days_to_maturity: '120',
    yield: '3'
  },
  {
    seed_id: 114,
    trade_name: 'SEEDCO',
    crop_id: 10007,
    variety: 'SC SAFARI',
    seed_type: 'HYBRID',
    price_per_kg: 20.79,
    sowing_rate: '25',
    days_to_maturity: '120',
    yield: '3'
  },
  {
    seed_id: 115,
    trade_name: 'SEEDCO',
    crop_id: 10008,
    variety: 'SC BOUNTY',
    seed_type: 'HYBRID',
    price_per_kg: 60.5,
    sowing_rate: '25',
    days_to_maturity: '110',
    yield: '2.5'
  },
  {
    seed_id: 116,
    trade_name: 'SEEDCO',
    crop_id: 10014,
    variety: 'NS5655',
    seed_type: 'HYBRID',
    price_per_kg: 110.0,
    sowing_rate: '3',
    days_to_maturity: '120',
    yield: '2.5'
  },
  {
    seed_id: 117,
    trade_name: 'SEEDCO',
    crop_id: 10014,
    variety: 'SC SILA',
    seed_type: 'OPV',
    price_per_kg: 110.0,
    sowing_rate: '3',
    days_to_maturity: '115',
    yield: '1.5'
  },
  {
    seed_id: 118,
    trade_name: 'SEEDCO',
    crop_id: 10014,
    variety: 'SC HX102',
    seed_type: 'HYBRID',
    price_per_kg: 110.0,
    sowing_rate: '3',
    days_to_maturity: '120',
    yield: '3.5'
  },
  {
    seed_id: 119,
    trade_name: 'SEEDCO',
    crop_id: 10014,
    variety: 'SC HX101',
    seed_type: 'HYBRID',
    price_per_kg: 110.0,
    sowing_rate: '3',
    days_to_maturity: '120',
    yield: '3.5'
  },
  {
    seed_id: 120,
    trade_name: 'SEEDCO',
    crop_id: 10014,
    variety: 'SUMMER 70',
    seed_type: 'HYBRID',
    price_per_kg: 110.0,
    sowing_rate: '3',
    days_to_maturity: '120',
    yield: '4'
  },
  {
    seed_id: 121,
    trade_name: 'SEEDCO',
    crop_id: 10014,
    variety: 'MR BUSTER',
    seed_type: 'HYBRID',
    price_per_kg: 160.0,
    sowing_rate: '3-8',
    days_to_maturity: '80-110 ',
    yield: '6'
  },
  {
    seed_id: 122,
    trade_name: 'SEEDCO',
    crop_id: 10015,
    variety: 'SC NDUNA',
    seed_type: 'HYBRID',
    price_per_kg: 21.71,
    sowing_rate: '25',
    days_to_maturity: '125',
    yield: '5'
  },
  {
    seed_id: 123,
    trade_name: 'SEEDCO',
    crop_id: 10015,
    variety: 'SC SELECT',
    seed_type: 'HYBRID',
    price_per_kg: 21.71,
    sowing_rate: '25',
    days_to_maturity: '125',
    yield: '5'
  },
  {
    seed_id: 124,
    trade_name: 'CROSSCORN',
    crop_id: 10011,
    variety: 'CC521',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10 - 15',
    days_to_maturity: '115 - 120',
    yield: '8'
  },
  {
    seed_id: 125,
    trade_name: 'CROSSCORN',
    crop_id: 10011,
    variety: 'CC5646QP',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10 - 15',
    days_to_maturity: '117 - 120',
    yield: '8'
  },
  {
    seed_id: 126,
    trade_name: 'CROSSCORN',
    crop_id: 10011,
    variety: 'CC623QP',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10 - 15',
    days_to_maturity: '117 - 120',
    yield: '8'
  },
  {
    seed_id: 127,
    trade_name: 'CROSSCORN',
    crop_id: 10011,
    variety: 'WE5321',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '10 - 15',
    days_to_maturity: '110 - 120',
    yield: '8'
  },
  {
    seed_id: 128,
    trade_name: 'CROSSCORN',
    crop_id: 10011,
    variety: 'WE3128',
    seed_type: 'HYBRID',
    price_per_kg: 52.8,
    sowing_rate: '11 - 15',
    days_to_maturity: '111 - 120',
    yield: '8'
  },
  {
    seed_id: 129,
    trade_name: 'CROSSCORN',
    crop_id: 10011,
    variety: 'CC423',
    seed_type: 'OPV',
    price_per_kg: 52.8,
    sowing_rate: '10 - 15',
    days_to_maturity: '110 - 120',
    yield: '5'
  },
  {
    seed_id: 130,
    trade_name: 'CROSSCORN',
    crop_id: 10011,
    variety: 'CC422',
    seed_type: 'OPV',
    price_per_kg: 52.8,
    sowing_rate: '10 - 15',
    days_to_maturity: '117 - 120',
    yield: '5'
  },
  {
    seed_id: 131,
    trade_name: 'CROSSCORN',
    crop_id: 10011,
    variety: 'Nomad',
    seed_type: 'OPV',
    price_per_kg: 52.8,
    sowing_rate: '10 - 15',
    days_to_maturity: '117 - 120',
    yield: '5'
  },
  {
    seed_id: 132,
    trade_name: 'CROSSCORN',
    crop_id: 10011,
    variety: 'Okavango',
    seed_type: 'OPV',
    price_per_kg: 52.8,
    sowing_rate: '10 - 15',
    days_to_maturity: '117 - 120',
    yield: '5'
  },
  {
    seed_id: 133,
    trade_name: 'CROSSCORN',
    crop_id: 10014,
    variety: 'SBSH 151',
    seed_type: 'HYBRID',
    price_per_kg: 100.0,
    sowing_rate: '3',
    days_to_maturity: '90 - 105',
    yield: '2 - 3.5'
  },
  {
    seed_id: 134,
    trade_name: 'CROSSCORN',
    crop_id: 10014,
    variety: 'MR BUSTER',
    seed_type: 'HYBRID',
    price_per_kg: 160.0,
    sowing_rate: '3-8',
    days_to_maturity: '80-110 ',
    yield: '6'
  },
  {
    seed_id: 135,
    trade_name: 'CROSSCORN',
    crop_id: 10014,
    variety: 'SEGAOLANE',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '3',
    days_to_maturity: '125 - 130',
    yield: '1 -3.5'
  },
  {
    seed_id: 136,
    trade_name: 'CROSSCORN',
    crop_id: 10014,
    variety: 'NS 5540',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '3',
    days_to_maturity: '125 - 130',
    yield: '1 - 3.5'
  },
  {
    seed_id: 137,
    trade_name: 'CROSSCORN',
    crop_id: 10001,
    variety: 'Tswana Cowpeas',
    seed_type: 'OPV',
    price_per_kg: 41.8,
    sowing_rate: '10 - 20',
    days_to_maturity: '110',
    yield: '2.5'
  },
  {
    seed_id: 138,
    trade_name: 'CROSSCORN',
    crop_id: 10001,
    variety: 'Bechuana White',
    seed_type: 'OPV',
    price_per_kg: 41.8,
    sowing_rate: '10 - 20',
    days_to_maturity: '110',
    yield: '2.5'
  },
  {
    seed_id: 139,
    trade_name: 'CROSSCORN',
    crop_id: 10011,
    variety: 'CC45',
    seed_type: 'OPV',
    price_per_kg: 52.8,
    sowing_rate: '20- 25',
    days_to_maturity: '85 - 90',
    yield: '1.8- 2.2'
  },
  {
    seed_id: 143,
    trade_name: 'BAYER(DEKALB)',
    crop_id: 10016,
    variety: 'DKC 76-71',
    seed_type: 'HYBRID',
    price_per_kg: 132.0,
    sowing_rate: '5',
    days_to_maturity: '110-120  ',
    yield: '6.8'
  },
  {
    seed_id: 144,
    trade_name: 'BAYER(DEKALB)',
    crop_id: 10017,
    variety: 'DKC 73-72',
    seed_type: 'HYBRID',
    price_per_kg: 129.8,
    sowing_rate: '5',
    days_to_maturity: '110-120 ',
    yield: '6.1'
  },
  {
    seed_id: 145,
    trade_name: 'BAYER(DEKALB)',
    crop_id: 10017,
    variety: 'DKC 68-50',
    seed_type: 'HYBRID',
    price_per_kg: 129.8,
    sowing_rate: '5 -7 ',
    days_to_maturity: '110-120 ',
    yield: '6'
  },
  {
    seed_id: 146,
    trade_name: 'BAYER(DEKALB)',
    crop_id: 10017,
    variety: 'DKC 74-20',
    seed_type: 'HYBRID',
    price_per_kg: 149.6,
    sowing_rate: '5- 6',
    days_to_maturity: '110-120',
    yield: '4.7'
  },
  {
    seed_id: 147,
    trade_name: 'AGRIZOR',
    crop_id: 10004,
    variety: 'AKWA',
    seed_type: 'HYBRID',
    price_per_kg: 55.0,
    sowing_rate: '80-100',
    days_to_maturity: '120-150',
    yield: '3.5'
  },
  {
    seed_id: 148,
    trade_name: 'AGRIZOR',
    crop_id: 10004,
    variety: 'SELLIE PLUS',
    seed_type: 'HYBRID',
    price_per_kg: 55.0,
    sowing_rate: '80-100',
    days_to_maturity: '120-150',
    yield: '3'
  },
  {
    seed_id: 149,
    trade_name: 'AGRIZOR',
    crop_id: 10019,
    variety: 'AGSUN 8251',
    seed_type: 'HYBRID',
    price_per_kg: 319.0,
    sowing_rate: '5',
    days_to_maturity: '115',
    yield: '2.55'
  },
  {
    seed_id: 150,
    trade_name: 'AGRIZOR',
    crop_id: 10019,
    variety: 'AGSUN 5278',
    seed_type: 'HYBRID',
    price_per_kg: 323.4,
    sowing_rate: '5',
    days_to_maturity: '115',
    yield: '2.61'
  },
  {
    seed_id: 151,
    trade_name: 'AGRIZOR',
    crop_id: 10015,
    variety: 'KARIEGA',
    seed_type: 'HYBRID',
    price_per_kg: 16.72,
    sowing_rate: '120',
    days_to_maturity: '120',
    yield: '7'
  },
  {
    seed_id: 152,
    trade_name: 'AGRIZOR',
    crop_id: 10014,
    variety: 'MR BUSTER',
    seed_type: 'HYBRID',
    price_per_kg: 131.26,
    sowing_rate: '3-8',
    days_to_maturity: '80-110 ',
    yield: '6'
  },
  {
    seed_id: 153,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10014,
    variety: 'BWS 5028',
    seed_type: 'OPV',
    price_per_kg: 33.0,
    sowing_rate: '3',
    days_to_maturity: '80-85',
    yield: '1.5-3.0'
  },
  {
    seed_id: 154,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10014,
    variety: 'BWS 5050',
    seed_type: 'OPV',
    price_per_kg: 33.0,
    sowing_rate: '3',
    days_to_maturity: '85-90',
    yield: '1.5-3.0'
  },
  {
    seed_id: 155,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10014,
    variety: 'BSH 2',
    seed_type: 'HYBRID',
    price_per_kg: 33.0,
    sowing_rate: '3',
    days_to_maturity: '130',
    yield: '3.5-5.0'
  },
  {
    seed_id: 156,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10014,
    variety: 'SEPHALA',
    seed_type: 'OPV',
    price_per_kg: 33.0,
    sowing_rate: '3',
    days_to_maturity: '70-100',
    yield: '1.44-3.5'
  },
  {
    seed_id: 157,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10014,
    variety: 'SEGAOLANE',
    seed_type: 'OPV',
    price_per_kg: 33.0,
    sowing_rate: '3',
    days_to_maturity: '125-130',
    yield: '1.0-3.5'
  },
  {
    seed_id: 158,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10014,
    variety: 'BSH 1',
    seed_type: 'HYBRID',
    price_per_kg: 33.0,
    sowing_rate: '3',
    days_to_maturity: '120-130',
    yield: '3.0-6.0'
  },
  {
    seed_id: 159,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10014,
    variety: 'HOFU',
    seed_type: 'OPV',
    price_per_kg: 33.0,
    sowing_rate: '3',
    days_to_maturity: '115-125',
    yield: '1.5-3.5'
  },
  {
    seed_id: 160,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10014,
    variety: 'MAHUBE',
    seed_type: 'OPV',
    price_per_kg: 33.0,
    sowing_rate: '3',
    days_to_maturity: '100-125',
    yield: '1.0-3.0'
  },
  {
    seed_id: 161,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10014,
    variety: 'MMABAITSE',
    seed_type: 'OPV',
    price_per_kg: 33.0,
    sowing_rate: '3',
    days_to_maturity: '125-130',
    yield: '1.0-3.0'
  },
  {
    seed_id: 162,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10014,
    variety: 'KANYE STANDARD',
    seed_type: 'OPV',
    price_per_kg: 33.0,
    sowing_rate: '3',
    days_to_maturity: '125-131',
    yield: '1.0-3.0'
  },
  {
    seed_id: 163,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10014,
    variety: 'TOWN',
    seed_type: 'OPV',
    price_per_kg: 33.0,
    sowing_rate: '3',
    days_to_maturity: '125-130',
    yield: '1.5-3.5'
  },
  {
    seed_id: 164,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10014,
    variety: 'MARUPANTSE',
    seed_type: 'OPV',
    price_per_kg: 33.0,
    sowing_rate: '3',
    days_to_maturity: '125-130',
    yield: '1.5-3.5'
  },
  {
    seed_id: 165,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10014,
    variety: '65D',
    seed_type: 'OPV',
    price_per_kg: 33.0,
    sowing_rate: '3',
    days_to_maturity: '65-100',
    yield: '1.0-3.0'
  },
  {
    seed_id: 166,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10014,
    variety: '8D',
    seed_type: 'OPV',
    price_per_kg: 33.0,
    sowing_rate: '3',
    days_to_maturity: '80-105',
    yield: '1.0-3.0'
  },
  {
    seed_id: 167,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10011,
    variety: 'BWM 309',
    seed_type: 'OPV',
    price_per_kg: 33.0,
    sowing_rate: '10',
    days_to_maturity: '90-120',
    yield: '5'
  },
  {
    seed_id: 168,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10011,
    variety: 'BWM 401',
    seed_type: 'OPV',
    price_per_kg: 33.0,
    sowing_rate: '10',
    days_to_maturity: '120',
    yield: '5'
  },
  {
    seed_id: 169,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10011,
    variety: 'BWM 523',
    seed_type: 'OPV',
    price_per_kg: 33.0,
    sowing_rate: '10',
    days_to_maturity: '120-130',
    yield: '6'
  },
  {
    seed_id: 170,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10011,
    variety: 'BHM 0623',
    seed_type: 'HYBRID',
    price_per_kg: 33.0,
    sowing_rate: '10',
    days_to_maturity: '130',
    yield: '6'
  },
  {
    seed_id: 171,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10011,
    variety: 'KGALAGADI EARLY PEARL',
    seed_type: 'OPV',
    price_per_kg: 33.0,
    sowing_rate: '10',
    days_to_maturity: '130',
    yield: '3-6'
  },
  {
    seed_id: 172,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10011,
    variety: 'OTCH PEARL',
    seed_type: 'OPV',
    price_per_kg: 33.0,
    sowing_rate: '10',
    days_to_maturity: '125-130',
    yield: '2-3'
  },
  {
    seed_id: 173,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10011,
    variety: 'NR 473',
    seed_type: 'OPV',
    price_per_kg: 33.0,
    sowing_rate: '10',
    days_to_maturity: '135',
    yield: '2-3'
  },
  {
    seed_id: 174,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10011,
    variety: 'R 201',
    seed_type: 'OPV',
    price_per_kg: 33.0,
    sowing_rate: '10',
    days_to_maturity: '138',
    yield: '2-4'
  },
  {
    seed_id: 175,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10041,
    variety: 'SERERE 6A',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '2-3',
    days_to_maturity: '120',
    yield: '1.0-1.5'
  },
  {
    seed_id: 176,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10041,
    variety: 'BONTLE -ICMV 88908',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '2-3',
    days_to_maturity: '90-100',
    yield: '1.5-1.8'
  },
  {
    seed_id: 177,
    trade_name: 'AGRICULTURAL  RESEARCH- BOTSWANA',
    crop_id: 10041,
    variety: 'LEGAKWE -SDMV 89008',
    seed_type: 'OPV',
    price_per_kg: 30.8,
    sowing_rate: '2-3',
    days_to_maturity: '100-110',
    yield: '1.8-2.4'
  },
  {
    seed_id: 178,
    trade_name: 'AGRICULTURAL RESEARCH- BOTSWANA',
    crop_id: 10004,
    variety: 'SELLIE ',
    seed_type: 'OPV',
    price_per_kg: 40.7,
    sowing_rate: '20',
    days_to_maturity: '120-130',
    yield: '1-2'
  },
  {
    seed_id: 179,
    trade_name: 'AGRICULTURAL RESEARCH- BOTSWANA',
    crop_id: 10004,
    variety: 'NAKWANA (GC8-13)',
    seed_type: 'OPV',
    price_per_kg: 40.7,
    sowing_rate: '20',
    days_to_maturity: '115',
    yield: '1-2'
  },
  {
    seed_id: 180,
    trade_name: 'AGRICULTURAL RESEARCH- BOTSWANA',
    crop_id: 10004,
    variety: 'EOLWANE (S46)',
    seed_type: 'OPV',
    price_per_kg: 40.7,
    sowing_rate: '20',
    days_to_maturity: '120-130',
    yield: '1-2.5'
  },
  {
    seed_id: 181,
    trade_name: 'AGRICULTURAL RESEARCH- BOTSWANA',
    crop_id: 10004,
    variety: 'TSOSABANA -ICGS-31',
    seed_type: 'OPV',
    price_per_kg: 40.7,
    sowing_rate: '20',
    days_to_maturity: '120-130',
    yield: '1-2.5'
  },
  {
    seed_id: 183,
    trade_name: 'Kweneng North Horticultural Farmers',
    crop_id: 10038,
    variety: 'Sironaria',
    seed_type: 'OPV',
    price_per_kg: 220.0,
    sowing_rate: '10',
    days_to_maturity: '125-155',
    yield: '4.5-6'
  }
]
