import { useMutation } from '@tanstack/react-query'
import { AxiosError, type AxiosResponse } from 'axios'
import { verifyLease, type VerifyLeaseDTO } from 'features/officer/lease-management/api/verifyLease'
import { useState } from 'react'
import { type ApiResponse, type Lease } from 'types'

interface Response {
  status: boolean
  reference: string
}

interface UseAcceptOrRejectLease {
  isLoading: boolean
  error: string | null
  acceptLease: () => void
  rejectLease: (reason: string) => void
  response: Response | null
}

export const useAcceptOrRejectLease = (
  lease: Lease,
  officerId: number | null | undefined
): UseAcceptOrRejectLease => {
  const [error, setError] = useState<string | null>(null)
  const [response, setResponse] = useState<Response | null>(null)

  const acceptLeaseMutation = useMutation({
    mutationFn: async (verifyDTO: VerifyLeaseDTO) => {
      return verifyLease(verifyDTO)
    }
  })
  const rejectLeaseMutation = useMutation({
    mutationFn: async (verifyDTO: VerifyLeaseDTO) => {
      return verifyLease(verifyDTO)
    }
  })

  const acceptLease = (): void => {
    setError(null)
    setResponse(null)

    acceptLeaseMutation.mutate(
      {
        LEASE_ID: lease.lease_id,
        LAST_UPDATED_BY: officerId,
        STATUS_ID: 4,
        LEASE_MESSAGE: 'lease successfully verified'
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            setResponse({
              status: true,
              reference: result?.data[0]?.file_reference
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const errMsg = err?.response as AxiosResponse<ApiResponse<[]>>
            setError(errMsg.data.message)
          } else {
            const errMsg = err as Error
            setError(errMsg.message)
          }
        }
      }
    )
  }

  const rejectLease = (reason: string): void => {
    setError(null)
    setResponse(null)

    acceptLeaseMutation.mutate(
      {
        LEASE_ID: lease.lease_id,
        LAST_UPDATED_BY: officerId,
        STATUS_ID: 2,
        LEASE_MESSAGE: reason
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            setResponse({
              status: true,
              reference: result?.data[0]?.file_reference
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const errMsg = err?.response as AxiosResponse<ApiResponse<[]>>
            setError(errMsg.data.message)
          } else {
            const errMsg = err as Error
            setError(errMsg.message)
          }
        }
      }
    )
  }

  return {
    isLoading: rejectLeaseMutation.isLoading || acceptLeaseMutation.isLoading,
    error,
    rejectLease,
    acceptLease,
    response
  }
}
